













































import { Component, Vue, Prop } from 'vue-property-decorator'
import { PromotionsItemListType } from '@/types/promotions'

@Component
export default class PromotionsCard extends Vue {
  @Prop({ type: Object })
  promotion!: PromotionsItemListType

  statusColor = {
    NEW: 'default',
    ACTIVE: 'success',
    FINISHED: 'gray',
    BLOCKED: 'danger',
    MODERATION: 'warning',
    REQUIRED_CHANGE: 'purple',
  }

  promotionStatus = {
    NEW: 'Новая',
    ACTIVE: 'Активная',
    MODERATION: 'На модерации',
    FINISHED: 'Завершенная',
    BLOCKED: 'Заблокированная',
    REQUIRED_CHANGE: 'Требует изменений',
  }

  promotionRule = {
    counties: 'Страна',
    regions: 'Регион',
    cities: 'Город',
    shops: 'Магазин',
    users: 'Участник',
    artnums: 'Артикул',
    masks: 'Код',
    prefixes: 'Префикс',
  }

  setImage(image: string): object {
    return process.env.VUE_APP_BASE_URI + JSON.parse(image)[0]
  }

  setBonus(bonus: string): string {
    const result = JSON.parse(bonus)
    if (result.type === 'percent') {
      return `${result.number}%`
    } else {
      return `+ ${result.number}`
    }
  }

  handleShowPromotionsDetail(): void {
    this.$emit('show-promotion', this.promotion.id)
  }
}
