






































































































































































































































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import TextAreaCommon from '@/components/common/TextAreaCommon.vue'
import { promotionsAPI } from '@/api/promotions.api'
import { Form, Message } from 'element-ui'
import {
  FileType,
  FormDataType,
  FormRuleType,
  PromotionsCreateType,
  PromotionsRulesType,
  SelectType,
  SearchedData,
} from '@/types/promotions'
import { shopsAPI } from '@/api/shops.api'
import { vendorsAPI } from '@/api/vendors.api'
import { membersAPI } from '@/api/members.api'
import { codesAPI } from '@/api/codes.api'

const CLEARED_FORM: FormDataType = {
  name: '',
  dateStart: '',
  dateComplete: '',
  codeActivationForm: true,
  description: '',
  bonusType: 'Процентный',
  bonus: '',
  rules: [
    {
      key: 'counties',
      include: true,
      value: '',
      label: 'Страна',
    },
  ],
  photo: [],
}

@Component({
  components: { TextAreaCommon, SelectCommon, InputCommon, DatePickerCommon },
})
export default class AddPromotionForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: Array })
  rules!: PromotionsRulesType[]

  $refs!: {
    form: Form
  }

  form: FormDataType = JSON.parse(JSON.stringify(CLEARED_FORM))

  searchedData: SearchedData[] = []

  showSelect = false

  formRules = {
    name: [
      {
        required: true,
        message: '',
        trigger: 'blur',
      },
    ],
    dateStart: [
      {
        required: true,
        message: '',
        trigger: 'blur',
      },
    ],
    dateComplete: {
      required: true,
      message: '',
      trigger: 'blur',
    },
    description: {
      required: true,
      message: '',
      trigger: 'blur',
    },
    bonus: {
      required: true,
      message: '',
      trigger: 'blur',
    },
  }

  get uploadedPhotos(): string[] {
    return this.$store.getters['files/uploadedFiles']
  }

  uploadPhoto(file: FileType): void {
    this.form.photo.push(file)
    this.$store.dispatch('files/uploadFiles', file.raw)
  }

  clearPhoto(index: number): void {
    this.form.photo.splice(index, 1)
    this.$store.commit('files/deleteFiles', index)
  }

  clearFiles(): void {
    this.$store.commit('files/clearFiles')

    this.searchedData = []
    this.form = JSON.parse(JSON.stringify(CLEARED_FORM))
  }

  handleCloseDialog(): void {
    this.clearFiles()
    this.$emit('change', false)
  }

  handleSelectRule(rule: PromotionsRulesType): void {
    this.form.rules.push({
      include: true,
      value: '',
      label: rule.name,
      key: rule.code,
    })
  }

  handleDeleteRule(index: number): void {
    this.form.rules.splice(index, 1)

    this.searchedData.splice(index, 1)
  }

  async handleAddPromotion(): Promise<void> {
    const rules: FormRuleType[] | Record<string, any> = {}

    let isValid = false

    await this.$refs.form.validate(async (valid) => {
      isValid = valid
    })

    if (!isValid) {
      return
    }

    this.form.rules.forEach((rule, index) => {
      if (!Object.prototype.hasOwnProperty.call(rules, rule.key)) {
        rules[rule.key] = []
      }

      const ruleItem = {
        value: rule.value,
        label: rule.value
          ? this.searchedData[index].find((item) => item.value === rule.value)
              ?.label
          : '',
        exclude: !rule.include,
      }
      if (rules[rule.key]) {
        rules[rule.key].push(ruleItem)
      } else {
        rules[rule.key] = [ruleItem]
      }
    })

    const bonus = {
      number: this.form.bonus,
      type: this.form.bonusType === 'Процентный' ? 'percent' : 'fixed',
    }

    const params: PromotionsCreateType = {
      UF_NAME: this.form.name,
      UF_DESCRIPTION: this.form.description,
      UF_RULES: JSON.stringify(rules),
      UF_IMAGES: JSON.stringify(this.uploadedPhotos),
      UF_BONUS: JSON.stringify(bonus),
      UF_DATE_START: this.form.dateStart,
      UF_DATE_END: this.form.dateComplete,
      UF_FORM: this.form.codeActivationForm,
    }

    const [error] = await promotionsAPI.promotionsCreate(params)

    if (!error) {
      this.$emit('add-promotion')

      await this.$store.dispatch('notifications/getNotificationsCount')

      this.clearFiles()
    } else {
      Message.error('Заполните все необходимые поля')
    }
  }

  async handleSearchData(
    value: string,
    currentRule: string,
    index: number
  ): Promise<void> {
    let searchData: SelectType[] | undefined = []

    if (value === '') {
      this.showSelect = false
      return
    } else {
      this.showSelect = true
    }

    switch (currentRule) {
      case 'counties':
        {
          const [error, data] = await shopsAPI.getCountry(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'cities':
        {
          const [error, data] = await shopsAPI.getCity(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'regions':
        {
          const [error, data] = await shopsAPI.getRegion(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'shops':
        {
          const [error, data] = await shopsAPI.getShops(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'shops_by_name':
        {
          const [error, data] = await shopsAPI.getShopsByName(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'prefixes':
        {
          const [error, data] = await vendorsAPI.getVendorPrefix(value)

          if (!error && data) {
            searchData = data
          }
        }
        break
      case 'users':
        {
          const [error, data] = await membersAPI.getUser(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'artnums':
        {
          const [error, data] = await codesAPI.getArtNumber(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'masks':
        {
          const [error, data] = await codesAPI.getCode(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
    }

    this.$set(this.searchedData, index, searchData)
  }
}
