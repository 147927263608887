






































import { Component, Emit, Model, Vue } from 'vue-property-decorator'
import TextAreaCommon from '@/components/common/TextAreaCommon.vue'

@Component({
  components: { TextAreaCommon },
})
export default class ReasonBlockForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Emit('change')
  handleCloseDialog(): boolean {
    this.reason = ''

    return false
  }

  @Emit('set-reason')
  handleSubmitForm(): string {
    const reason = this.reason

    this.reason = ''

    return reason
  }

  reason = ''
}
