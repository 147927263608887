
























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { FiltersType, PromotionsItemListType } from '@/types/promotions'

@Component({
  components: {
    InputCommon,
  },
})
export default class PromotionsTable extends Vue {
  @Prop({ type: Array })
  promotions!: PromotionsItemListType[]

  @Prop({ type: Boolean })
  loading?: boolean

  promotionStatus = {
    NEW: 'Новая',
    ACTIVE: 'Активная',
    MODERATION: 'На модерации',
    FINISHED: 'Завершенная',
    BLOCKED: 'Заблокированная',
    REQUIRED_CHANGE: 'Требует изменений',
  }

  promotionRule = {
    counties: 'Страна',
    regions: 'Регион',
    cities: 'Город',
    shops: 'Магазин',
    users: 'Участник',
    artnums: 'Артикул',
    masks: 'Код',
    prefixes: 'Префикс',
  }

  statusColor = {
    NEW: 'default',
    ACTIVE: 'success',
    FINISHED: 'gray',
    BLOCKED: 'danger',
    MODERATION: 'warning',
    REQUIRED_CHANGE: 'purple',
  }

  setRowColor(status: string): string {
    return status === 'ACTIVE' || status === 'NEW' ? 'color-dark' : 'color-gray'
  }

  setImage(image: string): object {
    return process.env.VUE_APP_BASE_URI + JSON.parse(image)[0]
  }

  setBonus(bonus: string): string {
    const result = JSON.parse(bonus)
    if (result.type === 'percent') {
      return `${result.number}%`
    } else {
      return `+ ${result.number}`
    }
  }

  handleShowPromotionInfo(row: { id: number }): void {
    this.$emit('show-promotion-info', row.id)
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }
}
