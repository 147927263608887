































































































































































































































































import {
  Vue,
  Component,
  Model,
  Prop,
  Watch,
  Emit,
} from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import TextAreaCommon from '@/components/common/TextAreaCommon.vue'
import {
  EditPromotionType,
  FileType,
  FormDataType,
  FormRuleItemType,
  FormRuleType,
  PromotionsItemListType,
  PromotionsRulesType,
  SelectType,
} from '@/types/promotions'
import { promotionsAPI } from '@/api/promotions.api'
import { shopsAPI } from '@/api/shops.api'
import { vendorsAPI } from '@/api/vendors.api'
import { membersAPI } from '@/api/members.api'
import { codesAPI } from '@/api/codes.api'
import { ElForm } from 'element-ui/types/form'
import format from '@/utils/date'

@Component({
  components: { TextAreaCommon, SelectCommon, InputCommon, DatePickerCommon },
})
export default class EditPromotionForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: Array })
  rules!: PromotionsRulesType[]

  @Prop({ type: Object })
  foundPromotion!: PromotionsItemListType

  @Prop({ type: Array })
  foundCities?: string[]

  @Prop({ type: Boolean })
  loading!: boolean

  @Emit()
  updateUser(id: string): string {
    return id
  }

  @Emit()
  editPromotion(): void {
    return
  }

  @Watch('value')
  setForm(): void {
    if (!this.value) return

    const bonus = JSON.parse(this.foundPromotion.UF_BONUS)

    this.form.name = this.foundPromotion.UF_NAME
    this.form.dateStart = this.foundPromotion.UF_DATE_START
      ? format(this.foundPromotion.UF_DATE_START, 'DD.MM.YYYY', 'YYYY-MM-DD')
      : ''
    this.form.dateComplete = this.foundPromotion.UF_DATE_END
      ? format(this.foundPromotion.UF_DATE_END, 'DD.MM.YYYY', 'YYYY-MM-DD')
      : ''
    this.form.description = this.foundPromotion.UF_DESCRIPTION
    this.form.id = this.foundPromotion.id
    this.form.codeActivationForm = Boolean(+this.foundPromotion.UF_FORM)
    this.form.bonus = bonus.number
    this.form.bonusType =
      bonus.type === 'percent' ? 'Процентный' : 'Фиксированный'

    JSON.parse(this.foundPromotion.UF_IMAGES).forEach((photo: string) => {
      this.form.photo.push({
        url: process.env.VUE_APP_BASE_URI + photo,
      })
    })

    const newRules: FormRuleItemType[] = []
    const rules: FormRuleItemType = {
      ...JSON.parse(this.foundPromotion.UF_RULES),
    }

    Object.keys(rules).forEach((item) => {
      const currentRule: SelectType[] = rules[item]

      Object.values(currentRule).forEach((newRule) => {
        if (!newRule.value) {
          return
        }

        const foundRule: PromotionsRulesType = this.rules.find((rule) => {
          if (rule.code === item) {
            return item
          }
        }) as PromotionsRulesType

        const newFoundRule: FormRuleItemType = Object.assign(newRule, {
          name: foundRule.name,
          key: foundRule.code,
          exclude: !newRule.exclude,
        })

        newRules.push(newFoundRule)
      })

      this.form.rules = newRules

      newRules.forEach((rule, index) => {
        this.$set(this.searchedData, index, [rule])
      })
    })
  }

  $refs!: {
    form: ElForm
  }

  form: FormDataType = {
    name: '',
    dateStart: '',
    dateComplete: '',
    codeActivationForm: false,
    description: '',
    bonusType: '',
    bonus: '',
    rules: [],
    photo: [],
  }

  searchedData: SelectType[] = []

  get uploadedPhotos(): string[] {
    return this.$store.getters['files/uploadedFiles']
  }

  clearFiles(): void {
    this.$store.commit('files/clearFiles')

    this.searchedData = []
    this.$refs.form.resetFields()
  }

  clearPhoto(index: number): void {
    this.form.photo.splice(index, 1)
    this.$store.commit('files/deleteFiles', index)
  }

  uploadPhoto(file: FileType): void {
    this.form.photo.push(file)
    this.$store.dispatch('files/uploadFiles', file.raw)
  }

  handleSelectRule(rule: PromotionsRulesType): void {
    this.form.rules.push({
      exclude: false,
      value: '',
      label: rule.name,
      name: rule.name,
      key: rule.code,
    })
  }

  handleCloseDialog(): void {
    this.clearFiles()
    this.$emit('change', false)
  }

  handleDeleteRule(index: number): void {
    this.form.rules.splice(index, 1)
  }

  async handleUpdatePromotion(): Promise<void> {
    const rules: FormRuleType | Record<string, any> = {}

    this.form.rules.forEach((rule) => {
      if (!Object.prototype.hasOwnProperty.call(rules, rule.key)) {
        rules[rule.key] = []
      }

      rules[rule.key].push({
        value: rule.value,
        label: rule.value,
        exclude: !rule.exclude,
      })
    })

    const bonus = {
      number: this.form.bonus,
      type: this.form.bonusType === 'Процентный' ? 'percent' : 'fixed',
    }

    const photos = this.form.photo.map((photo) => new URL(photo.url).pathname)

    const params: EditPromotionType = {
      id: this.form.id,
      UF_NAME: this.form.name,
      UF_DESCRIPTION: this.form.description,
      UF_RULES: JSON.stringify(rules),
      UF_IMAGES: JSON.stringify([...photos, ...this.uploadedPhotos]),
      UF_BONUS: JSON.stringify(bonus),
      UF_DATE_START: this.form.dateStart,
      UF_DATE_END: this.form.dateComplete,
      UF_FORM: this.form.codeActivationForm,
    }

    const [error, data] = await promotionsAPI.promotionsUpdate(params)

    if (!error && data) {
      this.editPromotion()
      this.clearFiles()

      await this.$store.dispatch('notifications/getNotificationsCount')
    }
  }

  async handleSearchData(
    value: string,
    currentRule: string,
    index: number
  ): Promise<void> {
    let searchData: SelectType[] | undefined = []

    if (value === '') return

    switch (currentRule) {
      case 'counties':
        {
          const [error, data] = await shopsAPI.getCountry(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'cities':
        {
          const [error, data] = await shopsAPI.getCity(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'regions':
        {
          const [error, data] = await shopsAPI.getRegion(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'shops':
        {
          const [error, data] = await shopsAPI.getShops(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'prefixes':
        {
          const [error, data] = await vendorsAPI.getVendorPrefix(value)

          if (!error && data) {
            searchData = data
          }
        }
        break
      case 'users':
        {
          const [error, data] = await membersAPI.getUser(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'artnums':
        {
          const [error, data] = await codesAPI.getArtNumber(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
      case 'masks':
        {
          const [error, data] = await codesAPI.getCode(value)

          if (!error && data) {
            searchData = data
          }
        }

        break
    }

    this.$set(this.searchedData, index, searchData)
  }
}
