var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"promotions-table",attrs:{"data":_vm.promotions},on:{"row-click":_vm.handleShowPromotionInfo,"sort-change":_vm.handleChangeSort}},[_c('el-table-column',{attrs:{"sortable":"custom","prop":"ID","label":"Id","min-width":"90","label-class-name":"color-gray pr-12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.setRowColor(row.UF_STATUS)},[_vm._v(" "+_vm._s(row.id)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"UF_STATUS","label":"Статус","width":"160","label-class-name":"color-gray pr-12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('dc-tag',{staticClass:"status-tag",attrs:{"mode":_vm.statusColor[row.UF_STATUS],"clickable":"","size":"xs"}},[_c('span',[_vm._v(_vm._s(_vm.promotionStatus[row.UF_STATUS]))])])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"UF_NAME","label":"Название","label-class-name":"color-gray pr-12","min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"promotions-table__name"},[(JSON.parse(row.UF_IMAGES).length)?_c('div',{staticClass:"promotions-table__image"},[_c('img',{attrs:{"src":_vm.setImage(row.UF_IMAGES),"width":"90px","height":"60px"}})]):_vm._e(),_c('span',{staticClass:"ml-12",class:_vm.setRowColor(row.UF_STATUS)},[_vm._v(" "+_vm._s(row.UF_NAME)+" ")])])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"UF_BONUS","label":"Бонус","min-width":"200","label-class-name":"color-gray pr-12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.setRowColor(row.UF_STATUS)},[_vm._v(" "+_vm._s(_vm.setBonus(row.UF_BONUS))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"rule","label":"Правила","label-class-name":"color-gray pr-12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"promotions-table__column-group"},[_c('div',{staticClass:"promotions-table__rules",class:_vm.setRowColor(row.UF_STATUS)},[_vm._l((JSON.parse(row.UF_RULES)),function(rules,key){return [_c('div',{staticClass:"color-gray"},[_c('span',[_vm._v(_vm._s(_vm.promotionRule[key]))])]),_vm._l((rules),function(rule){return [_c('div',[_c('span',[_vm._v(_vm._s(rule.exclude ? 'Исключая ' : 'Включая '))]),_c('span',[_vm._v(_vm._s(rule.label))])])]})]})],2)])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"UF_DATE_START","label":"Период","min-width":"200","label-class-name":"color-gray pr-12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"promotions-table__column-group"},[_c('span',{class:_vm.setRowColor(row.UF_STATUS)},[_vm._v(" "+_vm._s(row.UF_DATE_START)+" ")]),_c('span',{class:_vm.setRowColor(row.UF_STATUS)},[_vm._v(" - "+_vm._s(row.UF_DATE_END)+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }